import Http from '@/ship/Http';
import { UserNotificationsSettings } from '@/ship/Models/UserNotificationsSettings';

export const getUserNotificationsSettings = () => {
    return Http.get<UserNotificationsSettings>(`/user/get/notification-settings`);
};

export const setUserNotificationsSettings = (params: UserNotificationsSettings) => {
    return Http.post(`/user/set/notification-settings`, params);
};
